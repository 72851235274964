import * as actionTypes from './actionTypes';
import axios from 'axios';
import { reviewError } from '../../share/handlingErrors';

export const chatbot_start = () => {
    return {
        type: actionTypes.CHATBOT_START
    }
}

export const chatbotTemplate_success = (response, kindResponse) => {    
    return {
        type: actionTypes.CHATBOTTEMPLATE_SUCCESS,
        responseTemplate: response,
        kindResponse: kindResponse
    }
}

export const chatbotTelefono_success = (response, kindResponse) => {
    return {
        type: actionTypes.CHATBOTTELEFONO_SUCCESS,
        responseTelefono: response,
        kindResponse: kindResponse
    }
}

export const chatbotImagenes_success = (response) => {
    return {
        type: actionTypes.CHATBOTIMAGENES_SUCCESS,
        responseImagenes: response
    }
}

export const chatbotEnvios_success = (response) => {
    return {
        type: actionTypes.CHATBOTENVIOS_SUCCESS,
        responseEnvios: response
    }
}

export const chatbot_fail = (response) => {
    return {
        type: actionTypes.CHATBOT_FAIL,
        response: response
    }
}

export const getChatbotTemplatesExec = () => {
    return dispatch => {
        dispatch(chatbot_start());
        axios.get('http://34.214.42.132:3800/api/getTamplates', {
            headers: { 'Authorization': localStorage.getItem('tokenID') }
        })
            .then(response => {
                dispatch(chatbotTemplate_success(response, "templates"));
            })
            .catch(err => {
                const errorMsg = reviewError(err.response.status);
                dispatch(chatbot_fail(errorMsg.errorMessage));
            })
    }
}

export const getChatbotTelefonoExec = () => {
    return dispatch => {
        dispatch(chatbot_start());
        axios.get('http://34.214.42.132:3800/api/obtenertelefonos', {
            headers: { 'Authorization': localStorage.getItem('tokenID') }
        })
            .then(response => {                
                dispatch(chatbotTelefono_success(response, "telefono"));
            })
            .catch(err => {
                const errorMsg = reviewError(err.response.status);
                dispatch(chatbot_fail(errorMsg.errorMessage));
            })
    }
}

export const getChatbotImagenesExec = () => {
    return dispatch => {
        dispatch(chatbot_start());
        axios.get('http://34.214.42.132:3800/api/obtenerimagen', {
            headers: { 'Authorization': localStorage.getItem('tokenID') }
        })
            .then(response => {                
                dispatch(chatbotImagenes_success(response, "imagenes"));
            })
            .catch(err => {
                const errorMsg = reviewError(err.response.status);
                dispatch(chatbot_fail(errorMsg.errorMessage));
            })
    }
}

export const getChatbotEnviosExec = () => {
    return dispatch => {
        dispatch(chatbot_start());
        axios.get('http://34.214.42.132:3800/api/obtenerenvios', {
            headers: { 'Authorization': localStorage.getItem('tokenID') }
        })
            .then(response => {
                dispatch(chatbotEnvios_success(response, "envios"));
            })
            .catch(err => {
                const errorMsg = reviewError(err.response.status);
                dispatch(chatbot_fail(errorMsg.errorMessage));
            })
    }
}