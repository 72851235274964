import * as actionTypes from './actionTypes';
import axios from '../../axios-main';
//import {reviewError} from '../../share/handlingErrors';

export const reportsStart = () => {
    return{
        type: actionTypes.REPORTS_START
    }
};

export const reportsSuccess = (response, rspeLength) => {
    return{
        type: actionTypes.REPORTS_SUCCESS,
        response: response,
        rspeLength: rspeLength
    }
}

export const reportsFail = () => {
    return{
        type: actionTypes.REPORTS_FAIL
    }
}

export const reportsExec = () => {
    return dispatch => {
        dispatch(reportsStart());
        const addData = {
            tokenId: localStorage.getItem('tokenID')
        }
        axios.post('/getServices', addData,{
            headers: {'Authorization': localStorage.getItem('tokenID')}
        })
            .then(response => { 
                const numberGrid = [];
                for(let i=0; i < response.data.result.length; i++){
                    numberGrid.push(i);                    
                }                
                dispatch(reportsSuccess(response.data.result, numberGrid))
            })
            .catch(err => {
                dispatch(reportsFail(err))
            })
    }
}

export const reportsCaja = (diaInicial, diaFinal) => {
    return dispatch => {
        dispatch(reportsStart());
        const addData = {
            active: 1,
            diaInicial: diaInicial,
            diaFinal: diaFinal,
            tokenId: localStorage.getItem('tokenID')
        }
        axios.post('/doCorteCaja', addData,{
            headers: {'Authorization': localStorage.getItem('tokenID')}
        })
            .then(response => {                 
                const numberGrid = [];
                for(let i=0; i < response.data.result.length; i++){
                    numberGrid.push(i)
                }                
                dispatch(reportsSuccess(response.data.result, numberGrid))
            })
            .catch(err => {
                dispatch(reportsFail(err))
            })
    }
}

export const getDayCaja = (active, wantedDay) => {
    return dispatch => {
        dispatch(reportsStart());
        const addData = {
            active: active,
            wantedDay: wantedDay,
            tokenId: localStorage.getItem('tokenID')
        }
        axios.post('/getDayCaja', addData,{
            headers: {'Authorization': localStorage.getItem('tokenID')}
        })
            .then(response => {             
                const numberGrid = [];
                for(let i=0; i < response.data.result.length; i++){
                    numberGrid.push(i)
                }                
                dispatch(reportsSuccess(response.data.result, numberGrid))
            })
            .catch(err => {
                dispatch(reportsFail(err));
            })
    }
}