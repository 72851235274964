import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../share/utility';

const initialState = {
    sendingChatbot: false,
    successSendChatbot: false,
    errorSendChatbot: false,    
    responseSend: false,
}

const chatbot_send_start = (state, action) => {
    return updateObject(state, {
        sendingChatbot: true,
        successSendChatbot: false,
        errorSendChatbot: false,
        responseSend: false,
    })
}

const chatbot_send_success = (state, action) => {
    return updateObject(state, {
        sendingChatbot: false,
        successSendChatbot: true,
        errorSendChatbot: false,
        responseSend: action.responseSend,
    })
}

const chatbot_send_fail = (state, action) => {
    return updateObject(state, {
        sendingChatbot: false,
        successSendChatbot: false,
        errorSendChatbot: true,
        responseSend: action.responseSend,
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CHATBOT_SEND_START:
            return chatbot_send_start(state, action);
        case actionTypes.CHATBOT_SEND_SUCCESS:
            return chatbot_send_success(state, action);
        case actionTypes.CHATBOT_SEND_FAIL:
            return chatbot_send_fail(state, action);
        default:
            return state;
    }
}

export default reducer;