import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../share/utility';

const initialState = {
    loadingChatbot: false,
    successChatbot: false,
    errorChatbot: false,
    showChatbotModal: false,
    responseTemplate: false,
    responseTelefono: false,
    responseImagenes: false,
    responseEnvios: false,
    kindResponse: false
}

const chatbot_start = (state, action) => {
    return updateObject(state, {
        loadingChatbot: true,
        successChatbot: false,
        errorChatbot: false,
        responseTemplate: false,
        responseTelefono: false,
        responseImagenes: false,
        responseEnvios: false,
        kindResponse: false
    })
}

const chatbotTemplate_success = (state, action) => {
    return updateObject(state, {
        loadingChatbot: false,
        successChatbot: true,
        errorChatbot: false,
        showChatbotModal: false,
        responseTemplate: action.responseTemplate,
        kindResponse: action.kindResponse
    })
}

const chatbotTelefono_success = (state, action) => {
    return updateObject(state, {
        loadingChatbot: false,
        successChatbot: true,
        errorChatbot: false,
        showChatbotModal: false,
        responseTelefono: action.responseTelefono,
        kindResponse: action.kindResponse
    })
}

const chatbotImagenes_success = (state, action) => {
    return updateObject(state, {
        loadingChatbot: false,
        successChatbot: true,
        errorChatbot: false,
        showChatbotModal: false,
        responseImagenes: action.responseImagenes,        
    })
}

const chatbotEnvios_success = (state, action) => {
    return updateObject(state, {
        loadingChatbot: false,
        successChatbot: true,
        errorChatbot: false,
        showChatbotModal: false,
        responseEnvios: action.responseEnvios        
    })
}

const chatbot_fail = (state, action) => {
    return updateObject(state, {
        loadingChatbot: false,
        successChatbot: false,
        errorChatbot: true,
        showChatbotModal: false,
        responseTemplate: action.responseTemplate,
        responseTelefono: action.responseTelefono,
        responseImagenes: action.responseImagenes,
        responseEnvios: action.responseEnvios,
        kindResponse: false
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CHATBOT_START:
            return chatbot_start(state, action);
        case actionTypes.CHATBOTTEMPLATE_SUCCESS:
            return chatbotTemplate_success(state, action); 
        case actionTypes.CHATBOTTELEFONO_SUCCESS:
            return chatbotTelefono_success(state, action);
        case actionTypes.CHATBOTIMAGENES_SUCCESS:
            return chatbotImagenes_success(state, action);
        case actionTypes.CHATBOTENVIOS_SUCCESS:
            return chatbotEnvios_success(state, action);                       
        case actionTypes.CHATBOT_FAIL:
            return chatbot_fail(state, action);
        default:
            return state;
    }
}

export default reducer;