import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../share/utility';

const initialState = {
    loadingBarber: false,
    successBarber: false,
    errorBarber: false,
    showBarberModal: false,
    response: false
}

const barber_start = (state, action) => {
    return updateObject(state, {
        loadingBarber: true,
        successBarber: false,
        errorBarber: false,
        showExtraBarber: false,
        response: false
    })
}

const barber_success = (state, action) => {
    return updateObject(state, {
        loadingBarber: false,
        successBarber: true,
        errorBarber: false,
        showBarberModal: false,
        response: action.response
    })
}

const barber_fail = (state, action) => {
    return updateObject(state, {
        loadingBarber: false,
        successBarber: false,
        errorBarber: true,
        showBarberModal: false,
        response: action.response        
    })
}

const reducer = (state=initialState, action) => {
    switch(action.type){
        case actionTypes.BARBER_START:
            return barber_start(state, action);
        case actionTypes.BARBER_SUCCESS:
            return barber_success(state, action);
        case actionTypes.BARBER_FAIL:
            return barber_fail(state, action);
        default:
            return state;
    }
}

export default reducer;