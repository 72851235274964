import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../share/utility';

const initialState = {
    loadingLogger: false,
    successLogger: false,
    errorLogger: false,
    showLoggerModal: false
}

const logger_start = (state, action) => {
    return updateObject(state, {
        loadingLogger: true,
        successLogger: false,
        errorLogger: false,
    })
}

const logger_success = (state, action) => {
    return updateObject(state, {
        loadingLogger: false,
        successLogger: true,
        errorLogger: false,
        showLoggerModal: false,
        response: action.response
    })
}

const logger_fail = (state, action) => {
    return updateObject(state, {
        loadingLogger: false,
        successLogger: false,
        errorLogger: true,
        showLoggerModal: false,
        response: action.response        
    })
}

const reducer = (state=initialState, action) => {
    switch(action.type){
        case actionTypes.LOGGER_START:
            return logger_start(state, action);
        case actionTypes.LOGGER_SUCCESS:
            return logger_success(state, action);
        case actionTypes.LOGGER_FAIL:
            return logger_fail(state, action);
        default:
            return state;
    }
}

export default reducer;