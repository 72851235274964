import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../share/utility';

const initialState = {
    errorService: false,
    loadingService: false,
    response: [],
    rspeLength: 0,
    cleanTables: false
}

const reportsStart = (state, action) => {
    return updateObject(state, {
        errorService: null, 
        loadingService: true,
        cleanTables: true
    })
}

const reportsSuccess = (state, action) => {
    return updateObject(state, {
        errorService: null, 
        loadingService: false,
        cleanTables: false,
        response: action.response,
        rspeLength: action.rspeLength
    })
}

const reportsFail = (state, action) => {
    return updateObject(state, {
        errorService: true, 
        loadingService: false,
        cleanTables: false
    })
}

const reducer = (state=initialState, action) => {
    switch(action.type){
        case actionTypes.REPORTS_START:
            return reportsStart(state, action);
        case actionTypes.REPORTS_SUCCESS:
            return reportsSuccess(state, action);
        case actionTypes.REPORTS_FAIL:
            return reportsFail(state, action);
        default:
            return state;
    }
}

export default reducer;