import * as actionTypes from './actionTypes';
import axios from '../../axios-main';
import { reviewError } from '../../share/handlingErrors';

export const control_start = () => {
    return {
        type: actionTypes.CONTROL_START
    }
}

export const control_success = (response, respupdCtr) => {
    return {
        type: actionTypes.CONTROL_SUCCESS,
        response: response,
        respupdCtr: respupdCtr
    }
}

export const control_fail = () => {
    return {
        type: actionTypes.CONTROL_FAIL,
    }
}

export const getControlExec = () => {
    return dispatch => {
        dispatch(control_start());
        axios.get('/obtenerRegistros', 
            { headers: { 'Authorization': localStorage.getItem('tokenID') } })
            .then(response => {
                dispatch(control_success(response, false));
            })
            .catch(err => {
                const errorMsg = reviewError(err.response.status);
                dispatch(control_fail(errorMsg.errorMessage));
            })
    }
}

export const updateControlExec = (nombre, administrador, contacto, rol, active, direccion, ultimoPago, email) => {
    return dispatch => {
        dispatch(control_start());
        console.log("Updating Control -> Start");
        const addData = {
            nombre: nombre,
            administrador: administrador,
            contacto: contacto,        
            rol: rol,
            active: active,
            direccion: direccion,
            ultimoPago: ultimoPago,
            email: email,
        }
        axios.post('/actualizarRegistros', addData,{
            headers: { 'Authorization': localStorage.getItem('tokenID') }
        })
            .then(response => {   
                console.log("Updating Control -> Success");             
                console.log("Updating Control -> Success" + response);
                dispatch(control_success(false, response));
            })
            .catch(err => {                
                console.log("Updating Control -> Error");
                console.log("Updating Control -> Error" + err);
                dispatch(control_fail(err));
            })
    }
}