import * as actionTypes from './actionTypes';
import axios from '../../axios-main';
import {reviewError} from '../../share/handlingErrors';

export const logger_start = () => {
    return{
        type: actionTypes.LOGGER_START
    }
}

export const logger_success = (response) => {
    return{
        type: actionTypes.LOGGER_SUCCESS,
        response: response
    }
}

export const logger_fail = (response) => {
    return{
        type: actionTypes.LOGGER_FAIL,
        response: response
    }
}

export const addLogger = (datetime, producto, accion,) => {
    return dispatch => {
        dispatch(logger_start());
        const addData = {
            datetime: datetime,
            producto: producto,
            accion: accion,
            tokenId: localStorage.getItem('tokenID')
        }
        axios.post('/loggerInventario', addData)
            .then(response => {
                dispatch(logger_success(response));
            })
            .catch(err => {
                dispatch(logger_fail(err));
            })
    }
}

export const getloggerInv = () => {
    return dispatch => {
        dispatch(logger_start());
        const addData = {
            tokenId: localStorage.getItem('tokenID')
        }   
        axios.post('/getloggerInv', addData,{
            headers: {'Authorization': localStorage.getItem('tokenID')}
        })
            .then(response => {
                dispatch(logger_success(response));                
            })
            .catch(err => {                
                const errorMsg = reviewError(err.response.status);                
                dispatch(logger_fail(errorMsg.errorMessage));
            })
    }
}
