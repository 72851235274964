import * as actionTypes from './actionTypes';
import axios from '../../axios-main';
import {reviewError} from '../../share/handlingErrors';

export const extra_start = () => {
    return{
        type: actionTypes.EXTRA_START
    }
}

export const extra_success = (response) => {
    return{
        type: actionTypes.EXTRA_SUCCESS,
        response: response
    }
}

export const extra_fail = (response) => {
    return{
        type: actionTypes.EXTRA_FAIL,
        response: response
    }
}

export const addextraServiceExec = (accion, nombre, costo) => {
    return dispatch => {
        dispatch(extra_start());
        const addData = {
            accion: accion,
            nombre: nombre,
            costo: costo,
            tokenId: localStorage.getItem('tokenID')
        }
        axios.post(process.env.REACt_APP_SERVER_IP + '/addExtra', addData)
            .then(response => {
                dispatch(extra_success(response));
            })
            .catch(err => {
                dispatch(extra_fail(err));
            })
    }
}

export const getextraServiceExec = (accion) => {
    return dispatch => {
        dispatch(extra_start());
        const addData = {
            accion: accion,
            tokenId: localStorage.getItem('tokenID')
        }
        axios.post(process.env.REACT_APP_SERVER_IP + '/getExtra', addData)
            .then(response => {                
                dispatch(extra_success(response));                
            })
            .catch(err => {
                dispatch(extra_fail(err));
            })
    }
}

export const getAllExtra = () => {
    return dispatch => {
        dispatch(extra_start());
        const addData = {
            tokenId: localStorage.getItem('tokenID')
        }           
        axios.post('/getAllExtra', addData,
        {headers: {'Authorization': localStorage.getItem('tokenID')}})
            .then(response => {
                dispatch(extra_success(response));                 
            })
            .catch(err => {
                const errorMsg = reviewError(err.response.status);
                dispatch(extra_fail(errorMsg));
            })
    }
}

export const updateExtraServiceExec = (active, accion, nombre, costo, id) => {
    return dispatch => {
        dispatch(extra_start());
        const addData = {
            active: active,
            accion: accion,
            nombre: nombre,
            costo: costo,
            id: id,
            tokenId: localStorage.getItem('tokenID')
        }
        axios.post(process.env.REACT_APP_SERVER_IP + '/updateExtra', addData)
            .then(response => {
                dispatch(extra_success(response));
            })
            .catch(err => {
                dispatch(extra_fail(err));
            })
    }
}