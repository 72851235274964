import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../share/utility';

const initialState = {
    loadingControl: false,
    successControl: false,
    errorControl: false,
    showControlModal: false,
    responseControl: false,
    responseUpdCtr: false,
}

const control_start = (state, action) => {
    return updateObject(state, {
        loadingControl: true,
        successControl: false,
        errorControl: false,
        responseControl: false,
        responseUpdCtr: false,
    })
}

const control_success = (state, action) => {
    return updateObject(state, {
        loadingControl: false,
        successControl: true,
        errorControl: false,
        showControlModal: false,
        responseControl: action.response,
        responseUpdCtr: action.respupdCtr
    })
}

const control_fail = (state, action) => {
    return updateObject(state, {
        loadingControl: false,
        successControl: false,
        errorControl: true,
        showControlModal: false,
        responseControl: false,
        responseUpdCtr: false,
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CONTROL_START:
            return control_start(state, action);
        case actionTypes.CONTROL_SUCCESS:
            return control_success(state, action);
        case actionTypes.CONTROL_FAIL:
            return control_fail(state, action);
        default:
            return state;
    }
}

export default reducer;