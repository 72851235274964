import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../share/utility';

const initialState = {
    loadingExtra: false,
    successExtra: false,
    errorExtra: false,
    showExtraModal: false,
    accion: false,
    response: false
}

const extra_start = (state, action) => {
    return updateObject(state, {
        loadingExtra: true,
        successExtra: false,
        errorExtra: false,
        showExtraModal: false,
        response: false
    })
}

const extra_success = (state, action) => {
    return updateObject(state, {
        loadingExtra: false,
        successExtra: true,
        errorExtra: false,
        showExtraModal: false,
        response: action.response,
    })
}

const extra_fail = (state, action) => {
    return updateObject(state, {
        loadingExtra: false,
        successExtra: false,
        errorExtra: true,
        showExtraModal: false,
        response: action.response        
    })
}

const reducer = (state=initialState, action) => {
    switch(action.type){
        case actionTypes.EXTRA_START:
            return extra_start(state, action);
        case actionTypes.EXTRA_SUCCESS:
            return extra_success(state, action);
        case actionTypes.EXTRA_FAIL:
            return extra_fail(state, action);
        default:
            return state;
    }
}

export default reducer;