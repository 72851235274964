export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

export const ADD_SERVICE_START = 'ADD_SERVICE_START';
export const ADD_SERVICE_SUCCESS = 'ADD_SERVICE_SUCCESS';
export const ADD_SERVICE_FAIL = 'ADD_SERVICE_FAIL';
export const ADD_SERVICE_RESET = 'ADD_SERVICE_RESET';

export const REPORTS_START = 'REPORTS_START';
export const REPORTS_SUCCESS = 'REPORTS_SUCCESS';
export const REPORTS_FAIL = 'REPORTS_FAIL';
export const REPORTS_CORTE_CAJA = 'REPORTS_CORTE_CAJA';

export const TOLOGFILE_START = 'TOLOGFILE_START';
export const TOLOGFILE_SUCCESS = 'TOLOGFILE_SUCCESS';
export const TOLOGFILE_FAIL = 'TOLOGFILE_FAIL';

export const EXTRA_START = 'EXTRA_START';
export const EXTRA_SUCCESS = 'EXTRA_SUCCESS';
export const EXTRA_FAIL = 'EXTRA_FAIL';

export const BARBER_START = 'BARBER_START';
export const BARBER_SUCCESS = 'BARBER_SUCCESS';
export const BARBER_FAIL = 'BARBER_FAIL';

export const INVENT_START = 'INVENT_START';
export const INVENT_SUCCESS = 'INVENT_SUCCESS';
export const INVENT_FAIL = 'INVENT_FAIL';

export const LOGGER_START = 'LOGGER_START';
export const LOGGER_SUCCESS = 'LOGGER_SUCCESS';
export const LOGGER_FAIL = 'LOGGER_FAIL';

export const CHATBOT_START = 'CHATBOT_START';
export const CHATBOT_SUCCESS = 'CHATBOT_SUCCESS';
export const CHATBOTTEMPLATE_SUCCESS = 'CHATBOTTEMPLATE_SUCCESS'
export const CHATBOTTELEFONO_SUCCESS = 'CHATBOTTELEFONO_SUCCESS'
export const CHATBOTIMAGENES_SUCCESS = 'CHATBOTIMAGENES_SUCCESS'
export const CHATBOTENVIOS_SUCCESS = 'CHATBOTENVIOS_SUCCESS'
export const CHATBOT_FAIL = 'CHATBOT_FAIL';

export const CHATBOT_SEND_START = 'CHATBOT_SEND_START';
export const CHATBOT_SEND_SUCCESS = 'CHATBOT_SEND_SUCCESS';
export const CHATBOT_SEND_FAIL = 'CHATBOT_SEND_FAIL';

export const CONTROL_START = 'CONTROL_START';
export const CONTROL_SUCCESS = 'CONTROL_SUCCESS';
export const CONTROL_FAIL = 'CONTROL_FAIL';