import * as actionTypes from './actionTypes';
import axios from '../../axios-main';
import {reviewError} from '../../share/handlingErrors';

export const barber_start = () => {
    return{
        type: actionTypes.BARBER_START
    }
}

export const barber_success = (response) => {
    return{
        type: actionTypes.BARBER_SUCCESS,
        response: response
    }
}

export const barber_fail = (response) => {
    return{
        type: actionTypes.BARBER_FAIL,
        response: response
    }
}

export const addBarberExec = (nombre, apellido, edad, sexo, ingreso) => {
    return dispatch => {
        dispatch(barber_start());
        const addData = {
            nombre: nombre,
            apellido: apellido,
            edad: edad,
            sexo: sexo,
            ingreso: ingreso,
            tokenId: localStorage.getItem('tokenID')
        }
        axios.post('/addBarbero', addData)
            .then(response => {
                dispatch(barber_success(response));
            })
            .catch(err => {
                dispatch(barber_fail(err));
            })
    }
}

export const getBarberExec = () => {
    return dispatch => {
        dispatch(barber_start());
        const addData = {
            tokenId: localStorage.getItem('tokenID')
        }   
        axios.post('/getBarbero', addData,
        {headers: {'Authorization': localStorage.getItem('tokenID')}})
            .then(response => {
                dispatch(barber_success(response));                
            })
            .catch(err => {   
                const errorMsg = reviewError(err.response.status);                
                dispatch(barber_fail(errorMsg.errorMessage));
            })
    }
}

export const updateBarberExec = (active, nombre, apellido, edad, sexo, ingreso, id) => {
    return dispatch => {
        dispatch(barber_start());
        const addData = {
            active: active,
            nombre: nombre,
            apellido: apellido,
            edad: edad,
            sexo: sexo,
            ingreso: ingreso,
            id: id,
            tokenId: localStorage.getItem('tokenID')
        }
        axios.post('/updateBarbero', addData)
            .then(response => {
                dispatch(barber_success(response));
            })
            .catch(err => {
                dispatch(barber_fail(err));
            })
    }
}