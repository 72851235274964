import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../share/utility';

const initialState = {
    loadingInvent: false,
    successInvent: false,
    errorInvent: false,
    showInventModal: false,
    response: false
}

const invent_start = (state, action) => {
    return updateObject(state, {
        loadingInvent: true,
        successInvent: false,
        errorInvent: false,
        response: false
    })
}

const invent_success = (state, action) => {
    return updateObject(state, {
        loadingInvent: false,
        successInvent: true,
        errorInvent: false,
        showInventModal: false,
        response: action.response
    })
}

const invent_fail = (state, action) => {
    return updateObject(state, {
        loadingInvent: false,
        successInvent: false,
        errorInvent: true,
        showInventModal: false,
        response: action.response        
    })
}

const reducer = (state=initialState, action) => {
    switch(action.type){
        case actionTypes.INVENT_START:
            return invent_start(state, action);
        case actionTypes.INVENT_SUCCESS:
            return invent_success(state, action);
        case actionTypes.INVENT_FAIL:
            return invent_fail(state, action);
        default:
            return state;
    }
}

export default reducer;