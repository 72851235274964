import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../share/utility';

const initialState = {
    StartLogFile: false,
    SuccesslogFile: false,
    FaillogFile: false
}

const toLogFileStart = (state, action) => {
    return updateObject(state, {
        StartLogFile: true,
        SuccesslogFile: false,
        FaillogFile: false
    })
}

const toLogFileSuccess = (state, action) => {
    return updateObject(state, {
        StartLogFile: false,
        SuccesslogFile: true,
        FaillogFile: false
    })
}

const toLogFileFail = (state, action) => {
    return updateObject(state, {
        StartLogFile: false,
        SuccesslogFile: false,
        FaillogFile: true
    })
}

const reducer = (state=initialState, action) => {
    switch(action.type){
        case actionTypes.TOLOGFILE_START:
            return toLogFileStart(state, action);
        case actionTypes.TOLOGFILE_SUCCESS:
            return toLogFileSuccess(state, action);
        case actionTypes.TOLOGFILE_FAIL:
            return toLogFileFail(state, action);
        default:
            return state;
    }
}

export default reducer;