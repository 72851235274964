import React, { Component } from 'react';
import { Route, Switch, withRouter ,Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import asyncComponent from './hoc/asyncComponent/asyncComponent';
import Layout from './hoc/Layout/Layout';
import * as actions from './store/actions/index';
import './share/fontawesome';

const asyncHomePage = asyncComponent(() => {
  return import('./containers/HomePage/HomePage');
})
const asyncAuth = asyncComponent(() => {
  return import('./containers/Auth/Login');
})
const asyncAddService = asyncComponent(() => {
  return import('./containers/AddService/AddService');
})
const asyncPromos = asyncComponent(() => {
  return import('./containers/Promociones/Promociones');
})
const asyncReports = asyncComponent(() => {
  return import('./containers/Reports/Reports');
})
const asyncDashboard = asyncComponent(() => {
  return import('./containers/Dashboard/Dashboard');
})
const asyncServicios = asyncComponent(() => {
  return import('./containers/ServicesPage/Servicios');
})
const asyncAdministracion = asyncComponent(() => {
  return import('./containers/Administracion/Administracion');
})
const asyncInventario = asyncComponent(() => {
  return import('./containers/Inventario/Inventario');
})
const asyncChatbot = asyncComponent(() => {
  return import('./containers/chatbot/chatbot');
})
const asyncControl = asyncComponent(() => {
  return import('./containers/Control/Control');
})

class App extends Component{
  componentDidMount(){
    this.props.onTryAuthSignup();
  }

  render(){    
    let routes = (
      <Switch>
        <Route path='/promociones' component={asyncPromos}/>
        <Route path='/servicios' component={asyncServicios}/>
        <Route path='/login' component={asyncAuth}/>
        <Route path='/' exact component={asyncHomePage}/>
        <Redirect to='/' />
      </Switch>
    )

    if (this.props.isAuthenticated && localStorage.getItem('whois') === 'barbmaster') {      
      routes = (
        <Switch>
          <Route path='/control' component={asyncControl}/>
          <Route path='/chatbot' component={asyncChatbot} />
          <Route path='/inventario' component={asyncInventario} />
          <Route path='/dashboard' component={asyncDashboard} />
          <Route path='/reports' component={asyncReports} />
          <Route path='/addservice' component={asyncAddService} />
          <Route path='/administracion' component={asyncAdministracion} />
          <Route path='/' exact component={asyncHomePage} />
          <Redirect to='/' />
        </Switch>
      )      
    }

    if (this.props.isAuthenticated && localStorage.getItem('whois') === 'barbadmin') {      
      routes = (
        <Switch>
          <Route path='/inventario' component={asyncInventario} />
          <Route path='/dashboard' component={asyncDashboard} />
          <Route path='/reports' component={asyncReports} />
          <Route path='/addservice' component={asyncAddService} />
          <Route path='/administracion' component={asyncAdministracion} />
          <Route path='/' exact component={asyncHomePage} />
          <Redirect to='/' />
        </Switch>
      )
    }  
    
    if (this.props.isAuthenticated && localStorage.getItem('whois') === 'recepcion') {
      routes = (
      <Switch>        
        <Route path='/reports' component={asyncReports}/>
        <Route path='/addservice' component={asyncAddService}/>
        <Route path='/' exact component={asyncHomePage}/>
        <Redirect to='/' />
      </Switch>        
      )            
    }  

    return(
      <div>
        <Layout>
          {routes}
        </Layout>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return{
    onTryAuthSignup: () => dispatch(actions.authCheckState())
  }
}

const mapStateToProps = state => {
  return{
    isAuthenticated: state.auth.token !== null,
    whois: state.auth.whois
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App)) ;
