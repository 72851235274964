import * as actionTypes from './actionTypes';
import axios from '../../axios-main';
import {reviewError} from '../../share/handlingErrors';

export const invent_start = () => {
    return{
        type: actionTypes.INVENT_START
    }
}

export const invent_success = (response) => {
    return{
        type: actionTypes.INVENT_SUCCESS,
        response: response
    }
}

export const invent_fail = (response) => {
    return{
        type: actionTypes.INVENT_FAIL,
        response: response
    }
}

export const addInventExec = (nombre, costo, ppublico, cantidad, atributo) => {
    return dispatch => {
        dispatch(invent_start());
        const addData = {
            nombre: nombre,
            costo: costo,
            ppublico: ppublico,
            cantidad: cantidad,
            atributo: atributo,
            tokenId: localStorage.getItem('tokenID')
        }
        axios.post('/addInventario', addData)
            .then(response => {
                dispatch(invent_success(response));
            })
            .catch(err => {
                dispatch(invent_fail(err));
            })
    }
}

export const getInventExec = () => {
    return dispatch => {
        dispatch(invent_start());
        const addData = {
            tokenId: localStorage.getItem('tokenID')
        }   
        axios.post('/getInventario', addData,
        {headers: {'Authorization': localStorage.getItem('tokenID')}})
            .then(response => {
                dispatch(invent_success(response));                
            })
            .catch(err => {
                const errorMsg = reviewError(err.response.status);                
                dispatch(invent_fail(errorMsg.errorMessage));
            })
    }
}

export const updateInventExec = (nombre, costo, active, ppublico, cantidad, atributo, id) => {
    return dispatch => {
        dispatch(invent_start());
        const addData = {
            active: active,
            nombre: nombre,
            costo: costo,
            ppublico: ppublico,
            cantidad: cantidad,
            atributo: atributo,
            id: id,
            tokenId: localStorage.getItem('tokenID')
        }
        axios.post('/updateInventario', addData)
            .then(response => {
                dispatch(invent_success(response));
            })
            .catch(err => {
                dispatch(invent_fail(err));
            })
    }
}