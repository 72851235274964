export const reviewError = (codeNumber) => {
    let errorMessage = "No_Error";
    let isError = false
    switch(codeNumber){
        case 403:
            errorMessage = "Forbidden - Faltan Datos.";
            break;
        case 401:
            errorMessage = "El token ha expirado.";
            break;
        case 404:
            errorMessage = "El token no es valido.";
            break;
        default:
            errorMessage = "Sin autorización.";
            break;                                                        
    }
    if(errorMessage !== "No_Error"){isError = true}     
    return {errorMessage, isError};
}