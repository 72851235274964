import * as actionTypes from './actionTypes';
import axios from '../../axios-main';
// import { reviewError } from '../../share/handlingErrors';

export const chatbot_send_start = () => {
    return {
        type: actionTypes.CHATBOT_SEND_START
    }
}

export const chatbot_send_success = (responseSend) => {
    return {
        type: actionTypes.CHATBOT_SEND_SUCCESS,
        responseSend: responseSend,
    }
}


export const chatbot_send_fail = (responseSend) => {
    return {
        type: actionTypes.CHATBOT_SEND_FAIL,
        responseSend: responseSend
    }
}

export const chatBotSendExec = (entries, telefonos, imageId,kind) => {
    return dispatch => {
        dispatch(chatbot_send_start());
        const addData = {
            messageOut: entries,
            recipient: telefonos,
            imageId: imageId,
            kind: kind
        }
        axios.post('http://34.214.42.132:3800/api/sendWABAMessage', addData, {
            headers: { 'Authorization': localStorage.getItem('tokenID') }
        })
            .then(response => {                
                dispatch(chatbot_send_success(response))
            })
            .catch(err => {
                dispatch(chatbot_send_fail(err))
            })
    }
}
